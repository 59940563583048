
import http from './baseRequest'


const TOKEN_HEADER = 'Authorization'

/**
 * 用户注册的VO对象
 * @typedef {Object} adminRegistryVO
 * @property {string} username 用户名
 * @property {string} password 密码
 * @property {string} smsCode 选填：短信验证码
 * @property {string} emailCode 选填：邮箱验证码
 * 
 * @see {@link registryBySMS}
 */

/**
 * 通过手机号码注册
 * @param {adminRegistryVO} adminRegistryVO admin注册对象
 * @param {number} phoneNumber 手机号
 * @returns 
 */
export function registryBySMS(adminRegistryVO,phoneNumber) {
    return http({
        url:'/admin/registryBySMS',
        method:'post',
        data: adminRegistryVO,
        // {
        //     username:'lxy',
        //     password: '666',
        //     code: '111111'
        // }
        params: {phoneNumber},
    })
}

/**
 * 
 * @param {number} phoneNumber 通过手机号获取短信验证码
 */
export function getSMS(phoneNumber) {
    return http({
        url:'/admin/getSMS',
        method:'get',
        params: {phoneNumber}
    })
}



/**
 * @typedef {Object} adminLoginVO
 * @property {String} username 用户名
 * @property {String} password 密码
 */
/**
 * @param {adminLoginVO} adminLoginVO
 */
export function loginByPassword(adminLoginVO) {
    return http({
        method: 'post',
        url:'/admin/loginByPassword',
        data: adminLoginVO,
    })
}








/**
 * 有权限的接口，需要token
 */
export function getTotalAdminCount() {
    return http({
        method:'get',
        url:'/admin/getTotalAdminCount',
        headers : {
            Authorization:localStorage.getItem('accessToken')
        }
    })
}