import axios from "axios";
import { baseUrl } from "../utils/constants";
import { Message } from "element-ui";


const instance = axios.create()
instance.defaults.baseURL = baseUrl

instance.interceptors.response.use(function(resp) {
    if(resp.data.code === 200) {
        return Promise.resolve(resp.data.data)
    }
    else {
        Message({
            type: 'error',
            message: resp.data.msg
        })
        return Promise.reject(resp.data.msg)
    }
},function(error) {
    Message({
        type:'error',
        message:error
    })

    return Promise.reject(error)
})
export default instance;