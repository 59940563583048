import Vue from 'vue'
import VueRouter from 'vue-router'
import 'nprogress/nprogress.css'
import { start, done, configure } from 'nprogress'
import { delay } from './../utils/tools'
Vue.use(VueRouter)

configure({
  trickleSpeed: 20,
  showSpinner: false,
})

const routes = [
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: async () => {
        start();
        var s = await import('../views/login.vue');
        done();
        return s;
      }
    },
    {
      path: '/main',
      name: 'main',
      component: async () => {
        start()
        var s = await import('../views/main.vue')
        done()
        return s
      }
    }
  ]
})

router.beforeEach(function(to,from,next) {
  if(to.name !== 'login' && !localStorage.getItem('accessToken')) {
    next({name:'login'})
  } else {
    next()
  }
})

export default router
